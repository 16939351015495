:root {
  --under-background-color: #f5f5f5;
  --background-color: white;
  --text-color: black;
  --sitemap-color: lightgrey;
  --carousel-legend-text-color: white;
  --carousel-legend-background-color: black;
}

.dark-mode {
  --under-background-color: rgb(56, 56, 56);
  --background-color: black;
  --text-color: white;
  --sitemap-color: rgb(238, 202, 199);
  --carousel-legend-text-color: black;
  --carousel-legend-background-color: white;
}

* {
  /*outline: 1px solid red;*/
  font-family: "Inter", sans-serif;
}

.App {
  text-align: center;
  background-color: var(--under-background-color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--under-background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--background-color);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  background-color: var(--under-background-color);
  min-width: 100%;
  min-height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: Inter;
  color: var(--text-color);
}

.header-textbox {
  font-size: calc(8px + 2vmin);
  font-family: Inter;
  color: var(--background-color);
  display: flex;
  align-items: center;
}

.homepage {
  background-color: var(--under-background-color);
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: absolute;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
}

.inset-page {
  background-color: var(--background-color);
  height: 90vh;
  width: 95vw;
  border: 0.1px solid var(--text-color);
  margin: 0 auto;
  font-weight: 400;
}

/*sitemap*/
.sitemap .label {
  position: absolute;
  top: 82.3vh;
  left: 1vw;
  rotate: -90deg;
  font-size: 0.6em;
  font-weight: bold;
  opacity: 30%;
  color: var(--sitemap-color);
}

.sitemap .buttons {
  position: absolute;
  top: 77vh;
  font-size: 2vh;
  font-weight: 300;
}

.sitemap .link {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.sitemap .link:hover {
  text-decoration: none;
  background-color: var(--under-background-color);
}

/*.triple-column*/
.triple-column-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.triple-column-column {
  flex: 1;
  padding: 1.7vh;
  color: var(--text-color);
}

.triple-column-column:first-child {
  padding-top: 3.4vh;
  padding-left: 1.6vw;
  font-size: 4.2vh;
  font-weight: 300;
}

/*.bottom-text*/
.bottom-text {
  padding-top: 100%;
  text-align: right;
  font-size: 2.9vh;
  font-weight: 200;
}

.bottom-text div {
  height: 5vh;
  width: fit;
  margin-bottom: 0.2vh;
}

.bottom-text .c-text {
  display: inline-block;
  font-size: 2.5vh;
  height: fit-content;
  vertical-align: bottom;
  text-align: right;
}

.bottom-text .c-link {
  font-weight: 500;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.bottom-text .c-link:hover {
  text-decoration: none;
  background-color: var(--under-background-color);
}

.bottom-text .c-logo {
  height: 5vh;
  width: 5vh;
  padding: 0;
  vertical-align: middle;
}

/*.card*/
.card {
  max-width: 11.9vw;
  border-radius: 0.5rem;
  background-color: var(--background-color);
  box-shadow: 0 0.085vh 0.17vh 0 rgba(0, 0, 0, 0.05);
  border: 0.085vh solid transparent;
}

.card a {
  text-decoration: none;
}

.card-content {
  padding: 1.1rem;
}

.card-image {
  object-fit: cover;
  width: 100%;
  height: 12.5vh;
  background-color: var(--card-label-color);
}

.card-title {
  color: #111827;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
}

.card-desc {
  margin-top: 0.5rem;
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.card-action {
  display: inline-flex;
  margin-top: 1rem;
  color: var(--background-color);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  align-items: center;
  gap: 0.25rem;
  background-color: #2563eb;
  padding: 0.34vh 0.68vh;
  border-radius: 0.34vh;
}

.card-action span {
  transition: 0.3s ease;
}

.card-action:hover span {
  transform: translateX(0.34vh);
}

/*.infobutton*/
.infobutton {
  display: inline-block;
  border-radius: 0.6vh;
  border: none;
  background: var(--under-background-color);
  color: var(--background-color);
  font-family: inherit;
  text-align: center;
  font-size: 1.1vh;
  box-shadow: 0 0.042vh 2.2vh -0.05vh #464646;
  width: 10em;
  padding: 1em;
  transition: all 0.4s;
  cursor: pointer;
}

.infobutton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
}

.infobutton span:after {
  content: " me";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -1.6vh;
  transition: 0.7s;
}

.infobutton:hover span {
  padding-right: 3.55em;
}

.infobutton:hover span:after {
  opacity: 4;
  right: 0;
}

/*.carousel*/
.carousel .slide img {
  width: 100%;
  height: 25vh; /* sets height of images, thereby setting height of carousel */
  object-fit: cover; /* maintains the aspect ratio */
}

.carousel .slide .legend {
  background: var(--carousel-legend-background-color) !important;
  color: var(--carousel-legend-text-color) !important;
  font-size: 1.3vh;
}

.carousel .control-dots .dot {
  background: var(--background-color) !important;
}

/*.svg-container*/
.svg-container {
  height: fit-content;
  width: fit-content;
  display: flex;
}

.letter {
  color: var(--text-color);
}

/*darkmode-switch*/
.darkmode-switch {
  position: relative;
  width: 100px;
  height: 50px;
  --light: #d8dbe0;
  --dark: #28292c;
  --link: rgb(27, 129, 112);
  --link-hover: rgb(24, 94, 82);
}

.darkmode-switch .label {
  position: absolute;
  width: 100%;
  height: 50px;
  background-color: var(--dark);
  border-radius: 25px;
  cursor: pointer;
  border: 3px solid var(--dark);
}

.darkmode-switch .checkbox {
  position: absolute;
  display: none;
}

.darkmode-switch .slider {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.darkmode-switch .checkbox:checked ~ .slider {
  background-color: var(--light);
}

.darkmode-switch .slider::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  -webkit-box-shadow: inset 12px -4px 0px 0px var(--light);
  box-shadow: inset 12px -4px 0px 0px var(--light);
  background-color: var(--dark);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.darkmode-switch .checkbox:checked ~ .slider::before {
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
  background-color: var(--dark);
  -webkit-box-shadow: none;
  box-shadow: none;
}
